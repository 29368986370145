export const TYPE_GEAR_WOULD_TEST_OPTIONS = [
  {
    title: 'Footwear',
    options: [
      'Casual Footwear',
      'Sandals',
      'Watershoes',
      'Trail Running Shoes',
      'Road Running Shoes',
      'Minimalist Running Shoes',
      'Approach Shoes',
      'Lightweight Hiking Footwear',
      'Midweight Hiking Footwear',
      'Waterproof Hiking Footwear',
      'Mountaineering Boots',
      'Winter Hiking Footwear',
      'Insulated Footwear',
    ],
  },
  {
    title: 'Clothing',
    options: [
      'Casual Outdoor Wear',
      'Performance Base Layers',
      'Performance Mid-layers',
      'Insulated Outer Layers',
      'Waterproof Outer Layers',
      'Accessories',
    ],
  },
  {
    title: 'Camp and Hike',
    options: [
      'Hydration Products',
      'Camping Equipment',
      'Day Packs',
      'Backpacks',
      'Backpacking Equipment',
    ],
  },
];

export const USER_AGREEMENT_OPTIONS = [
  {
    id: 1,
    title: 'GENERAL PROVISIONS',
    options: [
      'Tester is participating in the Testing Program as a volunteer and, except as expressly provided herein, will not be entitled to any compensation in any form whatsoever from MERG.',
      'All prototypes, products, materials and features (including any related components, accessories and documentation) provided by MERG in connection with the Testing Program will be referred to in this Agreement as the “Test Products.”',
      'MERG contracts with outdoor recreation product designers and manufacturers to facilitate product testing. As such, while Tester will be working directly with MERG, Tester will be testing Test Products designed and/or manufactured by third parties (the  “Companies”). Tester understands that MERG has no control over the condition or design of the Test Products and hereby acknowledges that MERG expressly disclaims all liability associated with defects in the Test Products.',
      'Tester has a personal, non-exclusive non-transferrable license to use the applicable Test Products solely for the purpose of Tester’s evaluation and testing of the Test Products. Tester may not disclose (publicly or privately), sublicense, sell, assign, lease, lease, license, loan or otherwise transfer the Test Products to any third party, for any reason.',
      'Tester will be permitted use of the Test Products until such time as MERG instructs Tester to cease usage or requests that Tester return the Test Products.',
      'This Agreement will remain in force and effect until terminated, in writing, upon ten (10) days’ notice by MERG or Tester.',
    ],
  },
  {
    id: 2,
    title: 'MERG’S OBLIGATIONS',
    options: [
      'MERG will deliver the applicable Test Products to Tester at MERG’s expense.',
      'If the Test Products are required to be returned, MERG will provide shipping return services at its own expense.',
      'Neither MERG nor the Companies has an obligation to further develop or provide any updates or revisions to the Test Products, and the Companies reserve the right to alter or adjust performance specification for the Test Products as they may deem necessary or desirable.',
      'MERG may, but need not, provide instructions, safety information, warnings or cautions concerning the Test Products. However, in no event, will such information, or lack thereof, impute any liability on the part of MERG.',
    ],
  },
  {
    id: 3,
    title: 'TESTER’S OBLIGATIONS AND REPRESENTATIONS',
    options: [
      'Tester agrees to test and evaluate the Test Products as requested and described in any instructions provided by MERG.',
      'Tester agrees to familiarize itself with the Test Product information, instructions and documentation provided by MERG, and to only use or test the Test Products as directed.',
      'Tester agrees to utilize Test Products as much as possible during the test period. Test periods may vary, depending on the Test Products. MERG will provide information concerning specific tests and their duration, and may provide instructions to the Testers via email prior to the commencement of a new test.',
      'Tester will keep a log containing details of Tester’s use of Test Products and information concerning the performance of the Test Products. At the end of the Test Period, Tester may receive a final evaluation form for the Tester to provide both objective ratings and subjective comments.',
      'Tester agrees to periodically inspect Test Products to ensure that they are in good working condition. If Tester believes that a Test Product is in a dangerous condition or is in any way causing Tester pain or discomfort, or has the potential to cause injury to Tester, Tester will immediately stop utilizing the Test Product and immediately contact MERG. In addition, Tester will promptly notify MERG of any and all functional flaws, errors, anomalies, defects and other problems directly or indirectly associated with the Test Products known to or discovered by Tester.',
      'Tester agrees to provide MERG with such reports via the methods designated by MERG and to promptly respond to any and all reasonable inquiries, questionnaires, surveys and other test documents submitted to Tester by MERG.',
      'Depending upon the Test Product at issue, the performance of the Test Product and the needs of the Company, Tester may be required to return Test Products to MERG at the end of Test Period. In such event, Tester agrees to return the Test Products to MERG within fourteen (14) calendar days following receipt of MERG’s request to do so. However, Tester may be permitted to keep Test Products following the end of a product test, but Tester expressly agrees and acknowledges that will not sell, give away, or transfer possession of the Test Products to another. Additionally, Tester may be required to return the Test Products at any time and MERG in no way guarantees that Tester will be permitted to keep any Test Products.',
      'Any feedback, ideas, modifications, suggestions, improvements and the like made by Tester with respect to the Test Products (“Feedback”) will be the property of MERG. Tester agrees to assign, and hereby assigns, all right, title and interest worldwide in the Feedback and the related intellectual property rights to MERG. MERG may disclose or use the Feedback for any purposes whatsoever without any obligation to Tester.',
      'Tester agrees to pay all incidental costs associated with the testing of the Test Products and incurred during Tester’s possession of the Test Products, unless otherwise  agreed to in writing by both parties.',
      'Tester agrees and acknowledges that the use and potential retention of Test Products serves as adequate and complete consideration for Tester’s execution of this Agreement and participation in the Testing Program.',
      'Tester represents that neither Tester nor any household member has an employment or representative relationship with any outdoor recreation company who has any financial or non-financial interest in the success and/or failure of the Test Products, or products having a similar function or design, which would cause a conflict of interest. Tester agrees to immediately disclose whether they are currently or become a paid employee, agent, sponsor, independent contractor or ambassador for an outdoor recreation company to ensure no conflict of interest exists.',
      'Tester understands that MERG will not use Tester’s services on a permanent basis and Tester’s participation in the Testing Program is not guaranteed. Rather, MERG, in its sole discretion, will use Tester’s services as needed during the term of this Agreement. Tester acknowledges that, with respect to the Testing Program and the legal relationship between MERG and Tester, Tester is not an employee of MERG.',
      'Tester understands and acknowledges that Test Products are prototypes under test that are not intended for sale or distribution to the public and that neither MERG nor the Companies guarantee or warrant the Test Products in any way. Specifically, MERG and the Companies disclaim any and all express or implied warranties of merchantability and fitness for a particular purpose. Tester further understands that Test Products may contain defects that are unknown to MERG and the Companies. Tester hereby waives and releases all claims related to the Test Product and Testing Program against MERG and the Companies, arising out of or related to defects associates with the Test Product.',
    ],
  },
  {
    id: 4,
    title: 'CONFIDENTIALITY',
    options: [
      'In connection with the Testing Program, Tester and the members of Tester’s household (as applicable, “Recipients”) may be given access to certain Confidential Information (defined below) relating to both MERG and the Companies. MERG and Tester desire to protect and maintain the confidentiality of all Confidential Information that may be disclosed and/or created pursuant to this Agreement and/or the Testing Program, and to prevent the unauthorized use and disclosure of such Confidential Information.',
      '“Confidential Information” means all information, product samples and data provided to the Tester by MERG or the Companies unless explicitly stated not to be confidential by MERG, including, but not limited to such information, product sample, all non- public, confidential or proprietary information or information that under the circumstances would reasonably be considered confidential, whether previously disclosed or disclosed after the effective date of this Agreement, that includes without limitation, patents, patent applications, copyrights, trademarks, trade dress, trade secrets, inventions, innovations, processes, developments, research, technical data, know-how, specifications, drawings, designs, patterns, molds, engineering, product plans, released or unreleased products, samples, prototypes, models, fabrics, materials, manufacturing, marketing, sales or promotional plans, business policies or practices, prospective plans, financial information, customer, vendor or supplier lists, third party confidential information, pricing, sales volumes and projections, or profit margins. Confidential Information will not include information Tester is able to prove by documentary evidence: (i) is in the possession of Tester at the time of disclosure as shown by Tester’s files and records immediately prior to the time of disclosure; or (ii) prior to or after the time of disclosure becomes publicly available other than as a result of any improper action or inaction of Recipient.',
      'Tester understands and acknowledges that the Test Products are Confidential Information as defined in this Agreement. Tester will not publicize the Test Products nor his or her role as a Tester. Tester will treat any communications and reports prepared under this Agreement as Confidential Information and will not, and will ensure that all Recipients will not, divulge the existence or content of such communications or reports to any third party unless consent to do so is expressly given by MERG, in writing.',
      'Tester will not, and will ensure that all Recipients will not, disclose any Confidential Information to third parties and will not allow third parties to inspect Test Products unless consent to do so is expressly given by MERG, in writing. Tester will not, nor will Tester allow third parties, to sketch, photograph, video, create written descriptions, or otherwise preserve or disseminate, including via the internet or other electronic forms of dissemination, any information of any kind about Test Products and/or the Testing Program, unless consent to do so is expressly given by MERG, in writing.',
      'Tester will not, and will ensure that all Recipients will not, use any Confidential Information for its own use or for any purpose other than the Testing Program. Tester will take all reasonable measures to protect the secrecy of and avoid disclosure or use of Confidential Information. Tester will notify MERG in writing of any suspected misuse or misappropriation of Confidential Information that may come to Tester’s attention. Tester will not, and will ensure that all Recipients will not, make any statements or public announcements (including statements made via website or other digital or social media) with respect to Confidential Information or this Agreement, unless consent to do so is expressly given by MERG, in writing.',
      'Tester agrees and acknowledges that nothing in this Agreement is intended to grant any rights under any patent, trademark, trade name, service mark, service name, trade secret, copyright or other proprietary or intellectual property right of MERG or the Companies.',
    ],
  },
  {
    id: 5,
    title: 'PROPRIETARY RIGHTS; NO RIGHT TO COPY, MODIFY OR DISASSEMBLE.',
    options: [
      'The Test Products provided by MERG are proprietary to and property the Companies, as applicable. All applicable rights in all copyrights, trademarks, trade secrets, trade names, patents or other intellectual property rights in or associated with the Test Products are and will remain vested in the Companies, as applicable, and Tester will have no such intellectual property rights in the Test Products.',
      'Tester agrees to secure and protect the Test Products in a manner consistent with the maintenance of the rights of MERG and the Companies, and to take appropriate actions to secure and protect the Test Products as may be instructed by MERG.',
      'Tester will not, and will ensure that all Recipients will not, attempt to copy or reproduce the Test Products. In addition, Tester will not, and will ensure that all Recipients will not, attempt to reverse engineer, alter, modify, disassemble or decompile the Test Products, or any part thereof.',
    ],
  },
  {
    id: 6,
    title: 'DISCLAIMER OF WARRANTY',
    options: [
      'By its nature, the Test Products may contain functional flaws, errors, anomalies, defects and other problems that could cause failure of the Test Products, and the testing and quality assurance of the Test Products may not yet be completed. Tester acknowledges and agrees that, due to the developmental nature of the Test Products, any reliance on the Test Products is at Tester’s own risk. Without limiting any disclaimer of warranty or other limitation stated herein, Tester agrees that the Test Products are not considered to be suitable for commercial use and they may contain functional flaws, errors, anomalies, defects and other problems affecting their proper use and operation. ',
      'TESTER ACCEPTS THE TEST PRODUCTS “AS IS.” MERG MAKES NO WARRANTY OF ANY KIND REGARDING THE TEST PRODUCTS. MERG HEREBY EXPRESSLY DISCLAIMS ALL IMPLIED AND STATUTORY WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS. MERG MAKES NO WARRANTY THAT THE TEST PRODUCTS WILL MEET TESTER’S REQUIREMENTS, THAT THE TEST PRODUCTS WILL BE ERROR-FREE, OR THAT DEFECTS IN THE TEST PRODUCTS WILL BE CORRECTED.',
    ],
  },
  {
    id: 7,
    title: 'LIMITATION OF LIABILITY',
    options: [
      'The Test Products provided by MERG are proprietary to and property the Companies, as applicable. All applicable rights in all copyrights, trademarks, trade secrets, trade names, patents or other intellectual property rights in or associated with the Test Products are and will remain vested in the Companies, as applicable, and Tester will have no such intellectual property rights in the Test Products.',
      'Tester agrees to secure and protect the Test Products in a manner consistent with the maintenance of the rights of MERG and the Companies, and to take appropriate actions to secure and protect the Test Products as may be instructed by MERG.',
      'Tester will not, and will ensure that all Recipients will not, attempt to copy or reproduce the Test Products. In addition, Tester will not, and will ensure that all Recipients will not, attempt to reverse engineer, alter, modify, disassemble or decompile the Test Products, or any part thereof.',
    ],
  },
  {
    id: 8,
    title: 'INDEMNIFICATION',
    options: [
      'Tester has no injuries, health or medical conditions that would prevent or limit the Tester’s participation in the Testing Program. Tester understands and acknowledges that participation in the Testing Program may expose the Tester to bodily injury including, but not limited to, blisters, pulls or tears to muscles, ligaments and/or tendons, muscle strains, broken bones, and/or joint dislocations and that these injuries may arise from foreseeable or unforeseeable causes. Tester further understands and acknowledges that participation in the Testing Program will involve engaging in activities in a natural outdoor setting and that there are inherent risks associated with such activities. Tester voluntarily and knowingly recognizes, accepts and assumes all risks and dangers, and responsibility for any losses and/or damages, associated with participation in the Testing Program.',
      'To the fullest extent permitted by law, Tester, on behalf of itself, its representatives and heirs, hereby voluntarily releases, waives, discharges, holds harmless, and agrees to defend and indemnify MERG and the Companies, and their affiliates, owners, officers, directors, employees and agents from any and all losses, liabilities, claims, costs, damages, demands, expenses, and actions, including, without limitation, reasonable attorney fees and expenses, claims, actions or losses for bodily injury, property damage,  wrongful death, loss of services or otherwise, which may directly or indirectly result from, or arise out of Tester’s participation in the Testing Program and any negligent act or omission of MERG and its agents and employees, or that arise out of Tester’s breach of any representation, agreement or covenants contained herein. Tester specifically acknowledges and understands that it is releasing, discharging and waiving all claims or actions that Tester may have for the acts or omissions of MERG and/or the Companies, negligence, gross negligence and product liability.',
      'Tester hereby agrees to indemnify, defend and hold harmless MERG from all claims, losses, expenses, damages, costs of whatsoever kind or nature, including attorneys’ fees and court costs, arising out of or resulting from Tester’s breach or violation of this Agreement and/or MERG’s enforcement of this Agreement.',
    ],
  },
  {
    id: 9,
    title: 'MISCELLANEOUS',
    options: [
      'This Agreement cannot be modified or amended except by a writing signed by MERG and Tester.',
      'No party may assign any of its rights or delegate any of its obligations under this Agreement without the prior written consent of the other party, except that MERG may assign any of its rights and delegate any of its obligations hereunder to any subsidiary or affiliate of MERG.',
      'The terms of this Agreement may be waived by, and only by, a written instrument executed by the party against whom such waiver is sought to be enforced.',
      'If any term, provision, covenant or condition of this Agreement is held by a court of competent jurisdiction to be invalid, void or unenforceable, the remainder of  this Agreement shall remain in full force and effect and shall in no way be affected, impaired or invalidated.',
      'This Agreement and the rights and obligations of the Parties hereto shall be construed under and governed by the internal laws of the State of Utah without giving effect  to principles and provisions thereof relating to conflict or choice of laws, irrespective of the fact that any one of the Parties is now or may become a resident of or domiciled in a different state.',
      'Any proceeding arising out of or relating to this Agreement may be brought in the courts of the State of Utah, County of Salt Lake, or, if it has or can acquire jurisdiction, in the United States District Court for the District of Utah. Each of the parties irrevocably submits to the exclusive jurisdiction of each such court in any such legal or administrative proceeding, waives any objection it may now or hereafter have to venue or to convenience of forum, agrees that all claims in respect of the legal or administrative proceeding shall be heard and determined only in any such court and agrees not to bring any legal or administrative proceeding arising out of or relating to this Agreement in any other court. The Parties agree that either or both of them may file a copy of this paragraph with any court as written evidence of the knowing, voluntary and bargained agreement between the parties to irrevocably waive any objections to venue or to convenience of forum. Process in any legal or administrative proceeding referred to in this Subsection may be served on any party anywhere in the world.',
      'THE PARTIES HEREBY WAIVE, TO THE FULLEST EXTENT PERMITTED BY LAW, THE RIGHT TO TRIAL BY JURY, IN ANY ACTION, PROCEEDING OR COUNTERCLAIM, WHETHER IN CONTRACT OR TORT OR OTHERWISE, RELATING DIRECTLY OR INDIRECTLY TO THIS AGREEMENT, OR ANY MODIFICATIONS, EXHIBITS, ADDENDUM, SCHEDULES, OR SIMILAR THERETO. FURTHERMORE, THE PARTIES AGREE TO RESOLVE ALL DISPUTES BETWEEN THE PARTIES THROUGH BINDING ARBITRATION IN ACCORDANCE WITH THE AMERICAN ARBITRATION ASSOCIATION’S RULES.',
      'This Agreement embodies the entire agreement and understanding between the parties hereto relating to the subject matter hereof and supersedes and replaces any and all prior agreements and understandings relating to the subject matter hereof.',
      'The essential terms and conditions contained in this Agreement have been mutually negotiated between the parties. No ambiguity herein and therein shall be construed or interpreted as against the preparer of this Agreement, as each party shall be deemed to have contributed to the drafting of the provisions hereof.',
      'Tester acknowledges and agrees that a violation of this Agreement would cause irreparable injury to MERG and/or the Companies and that, in addition to any other remedies that may be available in law, equity or otherwise, MERG shall be entitled to obtain specific performance of this Agreement and/or preliminary or permanent injunctive relief against Tester for the breach of this Agreement.',
      'This Agreement may be executed in one or more counterparts, each of which will be deemed an original and all of which together constitute one instrument.',
    ],
  },
];

export const ACTIVITY_FIELDS = [
  'campingFreq',
  'vehicleBasedCampingFreq',
  'backpackingFreq',
  'hikingFreq',
  'roadRunningFreq',
  'trailRunningFreq',
  'walkingFreq',
  'casualWearFreq',
  'rockClimbingFreq',
  'alpineClimbingFreq',
  'mountaineeringFreq',
  'iceClimbingFreq',
  'backCountrySkiingFreq',
  'nordicSkiingFreq',
  'alpineSkiingFreq',
  'snowsShoeingFreq',
  'mountainBikingFreq',
  'roadBikingFreq',
  'bikepackingFreq',
  'huntingFreq',
  'fishingFreq',
  'supBoardingFreq',
  'kayakingFreq',
  'packraftingFreq',
];

export const CLOTHING_OR_BAG_OPTIONS = [
  'Casual Outdoor Wear',
  'Performance Base Layers',
  'Performance Mid-layers',
  'Insulated Outer Layers',
  'Waterproof Outer Layers',
];

export const BAG_OPTIONS = ['Day Packs', 'Backpacks'];

export const SHOE_OPTIONS = [
  'Casual Footwear',
  'Sandals',
  'Watershoes',
  'Trail Running Shoes',
  'Road Running Shoes',
  'Minimalist Running Shoes',
  'Approach Shoes',
  'Lightweight Hiking Footwear',
  'Midweight Hiking Footwear',
  'Waterproof Hiking Footwear',
  'Mountaineering Boots',
  'Winter Hiking Footwear',
  'Insulated Footwear',
];
