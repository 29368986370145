export const styles = {
  agreementLink: {
    '&.MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '304px',
      marginLeft: '7.5px',
    },
  },
  linkIcon: {
    width: '21px',
    height: '21px',
  },
  description: {
    '& .MuiTypography-root, &.MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  selectField: {
    width: 'min-content',
    marginBottom: '0px !important',
  },
  typography: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  addAddressBtn: {
    '&.MuiButtonBase-root.MuiButton-root': {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      background: 'transparent',
      border: 'none',
      color: '#9B51E0',
      padding: '0px',
      marginBottom: '16px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: '#DEDEDE',
        fontWeight: 400,
      },
    },
  },
  emptyAddress: {
    width: '100px',
    height: '0px',
  },

  noneField: {
    fontSize: '14px',
    color: '#444444',
  },
};
