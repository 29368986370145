export const styles = {
  confirmModal: {
    '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root': {
      maxWidth: '317px',
      width: '100%',
      minWidth: '317px',
    },
    '& .agree-btn': {
      backgroundColor: '#ffffff',
      color: '#000000',
      padding: '4px 16px',
    },
  },
};
