import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';

import { testerInfoStyle } from '../../styles';
import ContentWrapper from '../components/ContentWrapper';

const Contact = () => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext();

  const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, '');
    const match = digits.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    return match ? [match[1], match[2], match[3]].filter(Boolean).join('-') : value;
  };

  const [displayValue, setDisplayValue] = useState(formatPhoneNumber(values.phoneNumber || ''));

  const handleInputChange = (event) => {
    const rawValue = event.target.value.replace(/\D/g, ''); // Только цифры
    setDisplayValue(formatPhoneNumber(rawValue));

    handleChange({
      target: {
        name: 'phoneNumber',
        value: rawValue,
      },
    });
  };
  return (
    <ContentWrapper
      label='Contact'
      subLabel='Occasionally, we may need to reach out via text or phone call.'
    >
      <StyledLabel label='Phone number' sx={testerInfoStyle.label}>
        <TextField
          size='small'
          name='phoneNumber'
          onInput={handleInputChange}
          value={displayValue}
          error={touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          placeholder='Enter phone number'
          onBlur={handleBlur}
        />
      </StyledLabel>
    </ContentWrapper>
  );
};

export default Contact;
