import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import ADDRESS_SCHEMA from 'src/components/userProfile/testerTabs/components/modals/address.schema';
import { AddressGeneralModal } from 'src/components/userProfile/testerTabs/components/modals/AddressGeneralModal';
import PrimaryAddressModalContent from 'src/components/userProfile/testerTabs/components/modals/PrimaryAddressModalContent';
import { GET_TESTER_BY_ID } from 'src/graphql/queries';
import { useEditTester, useToasts } from 'src/hooks';

export const PrimaryAddressModal = ({ open, toggleModal, initialValues }) => {
  const [editTester] = useEditTester({
    successMessage: 'Primary address added',
    refetchQueries: [GET_TESTER_BY_ID],
  });
  const { ErrorMessage } = useToasts();

  const { id: testerId } = useParams();

  const onSubmit = (data) => {
    editTester({
      variables: {
        testerId,
        input: {
          testerInfo: {
            shipPreference: data.shipPreference,
            postalCode: data.postalCode,
            addressOne: data.addressOne,
            addressTwo: data.addressTwo,
            countryId: data.country.id,
            country: data.country.name,
            city: data.city,
            stateId: data.state.id,
          },
        },
      },
      onCompleted: () => {
        toggleModal();
      },
      onError: () => {
        ErrorMessage('Error. Please, try again.');
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ADDRESS_SCHEMA}
      validateOnMount={true}
    >
      {({ handleSubmit, errors }) => {
        return (
          <Form>
            <AddressGeneralModal
              title='Primary Address'
              open={open}
              toggleModal={toggleModal}
              handleClickSubmit={handleSubmit}
              disabledSave={!!Object.keys(errors).length}
            >
              <PrimaryAddressModalContent />
            </AddressGeneralModal>
          </Form>
        );
      }}
    </Formik>
  );
};
