import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ChevronDown } from 'src/assets/icons';
import { AddressSelect, DetailLabel, DetailSelect } from 'src/components/UI';
import { useGeo } from 'src/hooks/useGeo';

import { styles } from './styles';

const PrimaryAddressModalContent = () => {
  const { countries, states, setCountryId } = useGeo();

  const { handleChange, values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setCountryId(values.country?.id);
  }, [values.country?.id, setCountryId]);

  return (
    <>
      <DetailLabel label='Ship preference' required>
        <DetailSelect
          name='shipPreference'
          handleChange={handleChange}
          value={values.shipPreference}
          possibleValues={['FedEx/UPS', 'USPS']}
          placeholder=''
          iconComponent={() => (
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <ChevronDown />
            </Box>
          )}
          alwaysShown
          defaultOpen={false}
          selectField={styles.shipPreferenceSelect}
        />
      </DetailLabel>
      <DetailLabel label='Country' required>
        <AddressSelect
          value={values.country || ''}
          name='country'
          possibleValues={countries}
          handleChange={setFieldValue}
          placeholder='Select Country'
          sx={styles.addressSelectWrapper}
          popupIcon={
            <Box>
              <ChevronDown />
            </Box>
          }
        />
      </DetailLabel>
      <DetailLabel label='Address 1' required>
        <TextField
          name='addressOne'
          onChange={handleChange}
          value={values.addressOne || ''}
          placeholder='Address 1'
          sx={styles.textFieldWrapper}
        />
      </DetailLabel>
      <DetailLabel label='Address 2'>
        <TextField
          name='addressTwo'
          onChange={handleChange}
          value={values.addressTwo || ''}
          placeholder='Apt or Unit Number'
          sx={styles.textFieldWrapper}
        />
      </DetailLabel>
      <DetailLabel label='City' required>
        <TextField
          name='city'
          placeholder='Enter City'
          onChange={handleChange}
          value={values.city || ''}
          sx={styles.textFieldWrapper}
        />
      </DetailLabel>
      <DetailLabel label='State/Province' required>
        <AddressSelect
          value={values.state || ''}
          name='state'
          possibleValues={states}
          handleChange={setFieldValue}
          placeholder='Select State'
          getOptionLabel={(option) => option.stateCode || ''}
          sx={styles.addressSelectWrapper}
          popupIcon={
            <Box>
              <ChevronDown />
            </Box>
          }
        />
      </DetailLabel>
      <DetailLabel label='Postal Code' required>
        <TextField
          name='postalCode'
          placeholder='Enter Postal Code'
          onChange={handleChange}
          value={values.postalCode || ''}
          sx={{
            ...styles.textFieldWrapper,

            maxWidth: '180px',
          }}
        />
      </DetailLabel>
    </>
  );
};

export default PrimaryAddressModalContent;
