import * as Yup from 'yup';

const passwordRule = /([A-z-A-z0-9]{8,})/;

function password() {
  return this.test(
    'password',
    'Password must be 8 characters long and contain letters and numbers',
    (value) => (value ? passwordRule.test(value) : true),
  );
}

Yup.addMethod(Yup.string, 'password', password);

const emailsValidation = Yup.array()
  .transform((value, originalValue, array) => {
    if (array.isType(value) && value !== null) {
      return value;
    }
    return originalValue ? originalValue.split(/[\s,]+/) : [];
  })
  .of(
    Yup.string()
      .email('Invalid email address')
      .required('Enter another email')
      .test('Validate Email', 'Invalid email address', (value) => {
        const re =
          /^(([^<>()!#$%^&*[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }),
  )
  .required('Required');

export const loginSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string().required(),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});

export const createPasswordSchema = Yup.object({
  password: Yup.string().required(),
  repassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match'),
});

const CREATE_TESTER_SCHEMA_DEFAULT = {
  fullName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  weight: Yup.number().positive().nullable(),
  phoneNumber: Yup.number().positive().typeError('The value must be a number only'),
  isCallOrText: Yup.bool().nullable(),
  addressOne: Yup.string(),
  city: Yup.string(),
  country: Yup.object(),
  state: Yup.object(),
  postalCode: Yup.number(),
  birthdate: Yup.string().nullable(),
  isWorkForAnOutdoorCompany: Yup.bool(),
  typeGearWouldTest: Yup.array(),
  relatesOutdoorProductTestingDescription: Yup.string(),
  didYouHearAboutUs: Yup.string(),
  occupation: Yup.string(),
};

export const getCreateTesterSchema = (activities) => {
  const schema = activities.reduce((acc, next) => {
    acc[next.value] = Yup.string();
    return acc;
  }, CREATE_TESTER_SCHEMA_DEFAULT);

  return Yup.object(schema);
};

export const createTestSchema = Yup.object({
  brand: Yup.string().nullable().required('Required'),
  style: Yup.string().required('Required'),
  testOverview: Yup.string(),
  productUse: Yup.string(),
  testType: Yup.string(),
  duration: Yup.string(),
  participationReqs: Yup.string(),
  endDate: Yup.object().nullable(),
  quantity1: Yup.string(),
  quantity2: Yup.string(),
  status: Yup.string(),
  itemType: Yup.string(),
});

export const inviteUserSchema = Yup.object({
  emails: emailsValidation,
  role: Yup.string().required('Required'),
  brand: Yup.string()
    .nullable()
    .when('role', {
      is: (value) => value === 'CUSTOMER',
      then: Yup.string().nullable().required('Required'),
    }),
});

export const addReportSchema = Yup.object({
  linkName: Yup.string().required('Required'),
  link: Yup.string().url().required('Please enter a valid URL'),
  visibility: Yup.string().required('Required'),
});

export const createCustomerSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  brandId: Yup.string().nullable().required('Required'),
  contactName: Yup.string().required('Required'),
});

export const testsFilterSchema = Yup.object({
  brand: Yup.array().of(Yup.string()),
  type: Yup.array().of(Yup.string()),
  startDate: Yup.string().nullable(),
  endDate: Yup.string().nullable(),
  testerStatusExternal: Yup.string(),
  testerStatusInternal: Yup.string(),
  dateCreate: Yup.string().nullable(),
  status: Yup.array().of(Yup.string()),
});
