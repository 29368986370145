import { ACTIVITY_FIELDS } from './options';

export const getStepByFilledStep = (
  data,
  isClothingOrBagTypeGearSelected,
  isShoeTypeGearSelected,
  isBagTypeGearSelected,
) => {
  let steps = new Map([
    [0, ['firstName', 'lastName']],
    [1, ['country', 'addressOne', 'city', 'state', 'postalCode']],
    [2, ['phoneNumber']],
    [3, ['isWorkForAnOutdoorCompany']],
    [4, ['occupation']],
    [5, ['gender', 'birthdate', 'relatesOutdoorProductTestingDescription']],
    [6, ['isHaveDog', 'isRecreateWithKids']],
    [7, ['typeGearWouldTest']],
    [8, ['weightLbs', 'weightKgs', 'heightImperialFeet', 'heightMetric']],
    [9, ['topSizes']],
    [10, ['chestInches', 'chestCm']],
    [11, ['bottomSizes']],
    [
      12,
      [
        'waistAtNavelInches',
        'waistAtNavelCm',
        'waistAtPantsInches',
        'waistAtPantsCm',
        'hipsInches',
        'hipsCm',
      ],
    ],
    [13, ['inseamInches', 'inseamCm']],
    [14, ['generalShoeSizes', 'shoeWidth']],
    [15, ['activities']],
    [16, ['participationInTests']],
    [17, ['didYouHearAboutUs']],
  ]);

  const stepsArray = Array.from(steps);

  if (isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected) {
    steps = new Map(
      // eslint-disable-next-line
      [...stepsArray.slice(0, 14), ...stepsArray.slice(15)].map(([_, value], index) => [
        index,
        value,
      ]),
    );
  }
  if (!isClothingOrBagTypeGearSelected && !isBagTypeGearSelected && isShoeTypeGearSelected) {
    steps = new Map(
      // eslint-disable-next-line
      [...stepsArray.slice(0, 9), ...stepsArray.slice(14)].map(([_, value], index) => [
        index,
        value,
      ]),
    );
  }
  if (!isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected && !isBagTypeGearSelected) {
    steps = new Map(
      // eslint-disable-next-line
      [...stepsArray.slice(0, 9), ...stepsArray.slice(15)].map(([_, value], index) => [
        index,
        value,
      ]),
    );
  }
  if (!isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected && isBagTypeGearSelected) {
    steps = new Map(
      [
        ...stepsArray.slice(0, 9),
        ...stepsArray.slice(10, 11),
        ...stepsArray.slice(12, 13),
        ...stepsArray.slice(15), // eslint-disable-next-line
      ].map(([_, value], index) => [index, value]),
    );
  }
  if (!isClothingOrBagTypeGearSelected && isShoeTypeGearSelected && isBagTypeGearSelected) {
    steps = new Map(
      // eslint-disable-next-line
      [
        ...stepsArray.slice(0, 9),
        ...stepsArray.slice(10, 11),
        ...stepsArray.slice(12, 13),
        ...stepsArray.slice(14), // eslint-disable-next-line
      ].map(([_, value], index) => [index, value]),
    );
  }

  let currentStep = 0;

  while (steps.has(currentStep)) {
    const fields = steps.get(currentStep);

    if (
      fields.includes('activities') &&
      !ACTIVITY_FIELDS.some((field) => {
        return data[field] !== 'NEVER';
      })
    ) {
      return currentStep;
    }
    if (
      !fields.includes('activities') &&
      !fields.length !== 0 &&
      fields.some((field) => {
        if (Array.isArray(data[field])) {
          return !data[field].length;
        }

        if (typeof data[field] === 'boolean') {
          return false;
        }
        if (data[field] === 'none') {
          return true;
        }
        return !data[field];
      })
    ) {
      return currentStep;
    }
    currentStep += 1;
  }

  return currentStep;
};
