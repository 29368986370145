import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WelcomeContinueImg from 'src/assets/images/welcome-continue-profile.png';
import WelcomeImg from 'src/assets/images/welcome-create-profile.png';
import { RequestHandler } from 'src/components/requestHandler/requestHandler';
import { useAuthContext } from 'src/hooks';
import { theme } from 'src/theme';

import { testerInfoStyle, testerWrapperStyle } from '../components/userProfile/styles';

const TesterWelcomePage = () => {
  const { user, loading } = useAuthContext();
  const navigate = useNavigate();

  const isContinue = user?.firstName && user?.lastName;

  const handleOpenForm = () => {
    navigate('/welcome/profile');
  };

  return (
    <RequestHandler loading={loading}>
      <Box sx={testerWrapperStyle.welcomePage}>
        {!isContinue ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography sx={{ fontSize: '24px', lineHeight: '28px', fontWeight: 700 }}>
              Welcome to the Mountain Equipment Research Group
            </Typography>

            <img src={WelcomeImg} alt='welcome' />
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: 400,
                color: theme.colors.neutral.dark.mid,
              }}
            >
              Photo by Forest Woodward
            </Typography>
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}>
              Thank you for your interest in joining the MERG team! Please take a few minutes to
              create your profile. Before you can start testing, we’ll need some basic information
              such as your contact details and preferred activities—the more info we have, the more
              tests you may qualify for! Once your profile is complete, you'll be eligible to test
              gear.
            </Typography>
            <Button
              variant='contained'
              sx={{ ...testerInfoStyle.nextBtn, margin: '0 auto' }}
              type='button'
              onClick={handleOpenForm}
            >
              Create Profile
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography sx={{ fontSize: '24px', lineHeight: '28px', fontWeight: 700 }}>
              Finish creating your profile
            </Typography>

            <img src={WelcomeContinueImg} alt='welcome' />
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: 400,
                color: theme.colors.neutral.dark.mid,
              }}
            >
              Photo by Forest Woodward
            </Typography>
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}>
              You’ve started creating a profile, complete it now for access to gear testing
              opportunities!
            </Typography>
            <Button
              variant='contained'
              sx={{ ...testerInfoStyle.nextBtn, margin: '0 auto' }}
              type='button'
              onClick={handleOpenForm}
            >
              Continue Creating Profile
            </Button>
          </Box>
        )}
      </Box>
    </RequestHandler>
  );
};

export default TesterWelcomePage;
