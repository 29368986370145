import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { useMemo, useState } from 'react';
import { DetailInput, DetailLabel, DetailSelect } from 'src/components';
import AddressSummary from 'src/components/userProfile/testerTabs/components/AddressSummary';
import { YesOrNotText } from 'src/constants/constants';
import { booleanToString, stringToBoolean } from 'src/utils/helpers';

import { PrimaryAddressModal } from './modals/PrimaryAddressModal';
import { SecondaryAddressModal } from './modals/SecondaryAddressModal';
import { styles } from './styles';

export const Contact = ({ handleChange, values = {}, testerData }) => {
  const [isPrimaryOpen, setPrimaryOpen] = useState(false);
  const [isSecondaryOpen, setSecondaryOpen] = useState(false);

  const initialValues = {
    shipPreference: testerData.tester.shipPreference || 'FedEx/UPS',
    addressOne: testerData.tester.addressOne ?? '',
    addressTwo: testerData.tester.addressTwo ?? '',
    country: testerData.tester.country,
    state: testerData.tester.state,
    city: testerData.tester.city,
    postalCode: testerData.tester.postalCode,
  };

  const initialValuesSecondary = {
    shipPreference: testerData.secondaryAddress?.shipPreference || 'FedEx/UPS',
    addressOne: testerData.secondaryAddress?.addressOne ?? '',
    addressTwo: testerData.secondaryAddress?.addressTwo ?? '',
    country: testerData.secondaryAddress?.country,
    state: testerData.secondaryAddress?.state,
    city: testerData.secondaryAddress?.city,
    postalCode: testerData.secondaryAddress?.postalCode,
  };

  const showPrimarySummary = !!(
    initialValues.shipPreference &&
    initialValues.addressOne &&
    initialValues.country &&
    initialValues.state &&
    initialValues.city &&
    initialValues.postalCode
  );

  const showSecondarySummary = !!(
    initialValuesSecondary.shipPreference &&
    initialValuesSecondary.addressOne &&
    initialValuesSecondary.country &&
    initialValuesSecondary.state &&
    initialValuesSecondary.city &&
    initialValuesSecondary.postalCode
  );

  const handleAddAddress = () => {
    setSecondaryOpen((prev) => !prev);
  };

  const handleBoolChange = ({ target }) => {
    const { name, value } = target;

    handleChange({
      target: {
        name,
        value: stringToBoolean(value),
      },
    });
  };

  const handlePrimaryAddress = () => setPrimaryOpen((prev) => !prev);

  const isAllAddressesShown = useMemo(() => {
    const addressData = Object.entries(values);
    const missingAllAddressInfo = addressData.every(([, addressValue]) => !addressValue);

    return missingAllAddressInfo ? (
      <Typography onClick={handlePrimaryAddress} sx={styles.noneField}>
        Enter address
      </Typography>
    ) : (
      addressData.map(([key, value]) => (
        <Typography key={key} sx={styles.description} onClick={handlePrimaryAddress}>
          {value?.name || value || <Box sx={styles.emptyAddress} />}
        </Typography>
      ))
    );
  }, [values]);

  return (
    <Box>
      <DetailLabel label='Email'>
        <DetailInput
          name='email'
          handleChange={handleChange}
          value={values.email}
          placeholder='Enter Email'
          textTransform='lowercase'
          submitOnValueChange={true}
        />
      </DetailLabel>
      <DetailLabel label='Phone'>
        <DetailInput
          type='number'
          name='phoneNumber'
          handleChange={handleChange}
          value={values.phoneNumber}
          placeholder='Enter phone number'
          submitOnValueChange={true}
        />
      </DetailLabel>
      <DetailLabel label='Ok to call or text?'>
        <DetailSelect
          label='Ok to call or text?'
          name='isCallOrText'
          handleChange={handleBoolChange}
          value={booleanToString(values.isCallOrText)}
          possibleValues={YesOrNotText}
          submitOnValueChange={true}
          placeholder='Select yes or no'
        />
      </DetailLabel>
      {/* <DetailAddressFieldsWrapper values={values} setValues={setValues} /> */}
      {showPrimarySummary ? (
        <DetailLabel label={<Box>Primary Address</Box>}>
          <AddressSummary data={initialValues} edit={handlePrimaryAddress} />
        </DetailLabel>
      ) : (
        <>{isAllAddressesShown}</>
      )}
      {showSecondarySummary ? (
        <DetailLabel label={<Box>Secondary Address</Box>}>
          <AddressSummary data={initialValuesSecondary} edit={handleAddAddress} />
        </DetailLabel>
      ) : (
        <Button type='button' sx={styles.addAddressBtn} onClick={handleAddAddress}>
          Add Address
        </Button>
      )}
      {isPrimaryOpen && (
        <PrimaryAddressModal
          open={isPrimaryOpen}
          toggleModal={handlePrimaryAddress}
          initialValues={initialValues}
        />
      )}
      {isSecondaryOpen && (
        <SecondaryAddressModal
          open={isSecondaryOpen}
          toggleModal={handleAddAddress}
          initialValues={initialValuesSecondary}
          enteredFields={showSecondarySummary}
        />
      )}
    </Box>
  );
};

Contact.propTypes = {
  handleChange: func,
  setValues: func,
  values: instanceOf(Object),
};
