import About from './Steps/About';
import Activities from './Steps/Activities';
import Basics from './Steps/Basics';
import BottomSize from './Steps/BottomSize';
import BroughtMergFamily from './Steps/BroughtMergFamily';
import ChestMeasurement from './Steps/ChestMeasurement';
import Contact from './Steps/Contact';
import Demographics from './Steps/Demographics';
import DoForALiving from './Steps/DoForALiving';
import Gear from './Steps/Gear';
import HemLength from './Steps/HemLength';
import IndustryInsider from './Steps/IndustryInsider';
import OutsideDogsorKids from './Steps/OutsideDogsorKids';
import PrimaryShippingAddress from './Steps/PrimaryShippingAddress';
import ShoeSize from './Steps/ShoeSize';
import TestingFrequency from './Steps/TestingFrequency';
import TopSize from './Steps/TopSize';
import UserAgreement from './Steps/UserAgreement';
import WaistAndHipsMeasurements from './Steps/WaistAndHipsMeasurements';

const ContentByStep = ({
  step,
  toggleChangeAvatarModal,
  possibleValues,
  isShoeTypeGearSelected,
  isClothingOrBagTypeGearSelected,
  isBagTypeGearSelected,
}) => {
  const contentArray = [
    <Basics toggleChangeAvatarModal={toggleChangeAvatarModal} />,
    <PrimaryShippingAddress />,
    <Contact />,
    <IndustryInsider />,
    <DoForALiving />,
    <About genderPossibleValues={possibleValues.genders} />,
    <OutsideDogsorKids />,
    <Gear />,
    <Demographics />,
    <TopSize topSizePossibleValues={possibleValues.topSizeOptions} />,
    <ChestMeasurement />,
    <BottomSize bottomSizePossibleValues={possibleValues.bottomSizeOptions} />,
    <WaistAndHipsMeasurements />,
    <HemLength />,
    <ShoeSize
      shoeSizePossibleValues={possibleValues.shoeSizeOptions}
      shoeWidthPossibleValues={possibleValues.shoeWidth}
    />,
    <Activities
      activities={possibleValues.activities}
      frequencyValues={possibleValues.frequencyValues}
    />,
    <TestingFrequency participationInTestsPossibleValues={possibleValues.participationInTests} />,
    <BroughtMergFamily />,
    <UserAgreement />,
  ];

  if (isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected) {
    const newArray = [...contentArray.slice(0, 14), ...contentArray.slice(15)];

    return newArray[step];
  }
  if (!isClothingOrBagTypeGearSelected && !isBagTypeGearSelected && isShoeTypeGearSelected) {
    const newArray = [...contentArray.slice(0, 9), ...contentArray.slice(14)];

    return newArray[step];
  }
  if (!isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected && !isBagTypeGearSelected) {
    const newArray = [...contentArray.slice(0, 9), ...contentArray.slice(15)];

    return newArray[step];
  }
  if (!isClothingOrBagTypeGearSelected && !isShoeTypeGearSelected && isBagTypeGearSelected) {
    const newArray = [
      ...contentArray.slice(0, 9),
      ...contentArray.slice(10, 11),
      ...contentArray.slice(12, 13),
      ...contentArray.slice(15),
    ];
    return newArray[step];
  }
  if (!isClothingOrBagTypeGearSelected && isShoeTypeGearSelected && isBagTypeGearSelected) {
    const newArray = [
      ...contentArray.slice(0, 9),
      ...contentArray.slice(10, 11),
      ...contentArray.slice(12, 13),
      ...contentArray.slice(14),
    ];

    return newArray[step];
  }
  return contentArray[step];
};

export default ContentByStep;
