import { Box, MenuItem, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { CheckIcon } from 'src/components/UI/icons/CheckIcon';
import { stringToBoolean } from 'src/utils/helpers';

import ContentWrapper from '../components/ContentWrapper';
import { stepsStyles } from '../styles';

const DogsFidsArrayOptions = [
  { title: 'Dogs', name: 'isHaveDog' },
  { title: 'Kids', name: 'isRecreateWithKids' },
];

const OutsideDogsorKids = () => {
  const { handleChange, values } = useFormikContext();

  const handleChangeOption = (name) => () => {
    const isSelected = stringToBoolean(values[name]);

    handleChange({
      target: {
        name,
        value: !isSelected,
      },
    });
  };

  return (
    <ContentWrapper label='Do you regularly get outside with dogs or kids?'>
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '700',
          marginBottom: '8px',
        }}
      >
        Select all that apply
      </Typography>
      <Box sx={{ marginBottom: 3 }}>
        {DogsFidsArrayOptions.map(({ title, name }) => (
          <MenuItem
            key={title}
            value={stringToBoolean(values[name])}
            sx={stepsStyles.selectItem}
            onClick={handleChangeOption(name)}
          >
            <Box className='check-icon' sx={stepsStyles.checkIcon}>
              <CheckIcon isChecked={stringToBoolean(values[name])} />
            </Box>
            {title}
          </MenuItem>
        ))}
      </Box>
    </ContentWrapper>
  );
};

export default OutsideDogsorKids;
