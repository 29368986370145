export const PlusIconAgreement = () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12.9949 15.0051V21.3952L15.0049 21.3952L12.9949 21.3952V15.0051L6.60483 15.0051H12.9949ZM15.5299 21.9202L15.5299 15.5301H21.9199V12.4701L15.5299 12.4701L15.5299 6.08008L12.4699 6.08008V12.4701H6.07983V15.5301H12.4699V21.9202L15.5299 21.9202Z'
      fill='#676767'
    />
  </svg>
);
