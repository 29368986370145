import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmModal } from 'src/components/testOverview/confirmModal/confirmModal';
import ADDRESS_SCHEMA from 'src/components/userProfile/testerTabs/components/modals/address.schema';
import { AddressGeneralModal } from 'src/components/userProfile/testerTabs/components/modals/AddressGeneralModal';
import SecondaryAddressModalContent from 'src/components/userProfile/testerTabs/components/modals/SecondaryAddressModalContent';
import { GET_TESTER_BY_ID } from 'src/graphql/queries';
import { useEditTester, useToasts } from 'src/hooks';

import { styles } from './styles';

export const SecondaryAddressModal = ({ open, toggleModal, initialValues, enteredFields }) => {
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);

  const [editTester] = useEditTester({
    successMessage: 'Secondary address added',
    refetchQueries: [GET_TESTER_BY_ID],
  });

  const { id: testerId } = useParams();

  const [removeAddressFromProfile] = useEditTester({
    successMessage: 'Secondary address removed',
    refetchQueries: [GET_TESTER_BY_ID],
  });

  const { ErrorMessage } = useToasts();

  const onSubmit = (data) => {
    editTester({
      variables: {
        testerId,
        input: {
          secondaryAddress: {
            shipPreference: data.shipPreference,
            postalCode: data.postalCode,
            addressOne: data.addressOne,
            addressTwo: data.addressTwo,
            countryId: data.country.id,
            city: data.city,
            stateId: data.state.id,
          },
        },
      },
      onCompleted: () => {
        toggleModal();
      },
      onError: () => {
        ErrorMessage('Error. Please, try again.');
      },
    });
  };

  const handleRemoveToggle = () => {
    setRemoveModalIsOpen((prev) => !prev);
  };

  const handleRemoveAddress = () => {
    removeAddressFromProfile({
      variables: {
        testerId,
        input: {
          secondaryAddress: null,
        },
      },
      onCompleted: () => {
        toggleModal();
      },
      onError: () => {
        ErrorMessage('Error. Please, try again.');
      },
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ADDRESS_SCHEMA}
        validateOnMount={true}
      >
        {({ handleSubmit, errors }) => {
          return (
            <Form>
              <AddressGeneralModal
                title='Secondary Address'
                secondary
                open={open}
                toggleModal={toggleModal}
                handleClickSubmit={handleSubmit}
                disabledSave={!!Object.keys(errors).length}
                enteredFields={enteredFields}
                handleRemove={handleRemoveToggle}
              >
                <SecondaryAddressModalContent />
              </AddressGeneralModal>
            </Form>
          );
        }}
      </Formik>
      <ConfirmModal
        sx={styles.confirmModal}
        open={removeModalIsOpen}
        toggleModal={handleRemoveToggle}
        callback={handleRemoveAddress}
        labalTest='Are you sure you want to remove this address??'
        agreeButtonTex='Yes, Remove it'
      />
    </>
  );
};
